import clsx from 'clsx'
import { memo, useCallback, useMemo, useRef } from 'react'
import { Badge } from '../../components/badge/badge'
import { FavoriteButton } from '../../components/icon-ctas/favorite-button'
import { ThreeDTourButton } from '../../components/icon-ctas/three-d-tour-button'
import { Truncate } from '../../components/truncate/truncate'
import { getDealsText } from '../listing/get-deals-text'
import { getListingPriceText } from '../listing/get-listing-price-text'
import { ListingCardScrollSnapCarousel } from './listing-card-scroll-snap-carousel'
import { ListingCardSoftFilters } from './listing-card-soft-filters'
import styles from './listing-card-redesign.module.css'
import { useSubmitUserActivityHandler } from '../user-event-tracking/use-submit-user-activity-handler'
import { TrackImpression } from '../tagging/use-track-impression'
import { getHdTours } from '../detail/tour-from-home/get-tour-from-home-data'
import { ShareButton } from '../../components/icon-ctas/share-button'
import { useShareProperty } from '../detail/share-property/use-share-property'
import { BedCountData } from './bed-count-data/bed-count-data'
import { ListingCardResultsCTA } from './listing-card-results-cta'
import { useDebouncedCallback } from 'use-debounce'
import { SelectionOriginEnum } from '../search/search-map/search-map.store'
import { useSetActiveListingAtom } from '../search/hooks/use-active-listing-atom'
import { useListingCardImpressionTracking } from './use-listing-card-impression-tracking'
import type {
  ListingCardProps,
  ListingHandleMouseOverParams,
  ListingCardHomePageProps,
} from './listing-card.types'
import { useSetPlays } from '../optimization-photos/use-set-plays'
import { useSetWins } from '../optimization-photos/use-set-wins'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { CONTACT_FOR_PRICE } from '@brand/slots/detail/floor-plans/const'
import { getLocationBadgeText } from '@brand/search/get-location-badge-text'
import { LocationMatchBadge } from '../../components/location-match-badge/location-match-badge'
import { yieldOrContinue } from 'main-thread-scheduling'
import { ListingCardDetailPageLink } from './listing-card-detail-page-link'
import { getAmenityText } from './listing-card.utils'
import { getBedsText } from '../search/utils/get-listing-card-details'

const MemoizedListingCardScrollSnapCarousel = memo(
  ListingCardScrollSnapCarousel
)

export function ListingCardRedesignBase(
  props: ListingCardProps | ListingCardHomePageProps
) {
  const { updatePlays, listing } = props
  const { simplifiedCard, hasTodaysTopPick } = props as ListingCardHomePageProps
  const listingCardIndex = (props.listingCardIndex || 0) + 1
  const firstListingInteraction = useRef(false)
  const listingPhotos = listing.optimizedPhotos || listing.photos
  const firstPhoto = listingPhotos?.[0] ? [listingPhotos[0]] : []
  const todaysTopPickText = `Today's Top Pick`
  const { location } = listing

  const cityState =
    location?.city && location?.stateAbbr
      ? `${location?.city}, ${location.stateAbbr}`
      : ''

  const locationMatchText = useMemo(
    () =>
      getLocationBadgeText({
        locationBadgeRequirements: props?.locationBadgeRequirements,
        listing: props.listing,
      }),
    [props?.locationBadgeRequirements, props.listing]
  )

  const updateWins = useSetWins(!!listing.optimizedPhotos?.length)

  const isSwipeEnabledForMobile =
    props.featureFlags?.srp_swipe_scroll_version === 1

  const setActiveListing = useSetActiveListingAtom()

  const submitUserActivitySrpInteraction = useSubmitUserActivityHandler({
    listingId: props.listing.id,
    type: 'srp-interaction',
  })

  const redesignVersion = useFeatureVariable<number>(
    ['listing_card_redesign', 'version'],
    0
  )

  const withUpdatedStyles = redesignVersion === 2

  const dealsBadgeText = getDealsText(listing)
  const hasCategoryBadges = listing.categoryBadges?.length > 0
  const isPromotedListing = Boolean(
    props.isSpotlightListing || props.isFeaturedListing
  )
  const filterRelevance = props.filterRelevance

  const showFilterRelevance = withUpdatedStyles
    ? Boolean(filterRelevance?.unmatched?.length)
    : Boolean(
        filterRelevance || (isPromotedListing && props.softMatchesEnabled)
      )

  const dataTagSection =
    (props.isSpotlightListing && 'spotlight') ||
    (props.isFeaturedListing && 'featured_communities') ||
    (!listing.isUnpaid && 'paid') ||
    'free'
  //filterMatchResult can be null when this component is accessed in the saved page.
  //In that case, we display the listing price as is.
  const listingPriceText = getListingPriceText({
    priceRange: props.filterMatchResult?.prices,
    isBasicListing: props.listing.isBasic,
    fallbackPriceText: props.listing.priceText,
    availabilityStatus: props.listing.availabilityStatus,
  })

  const spotlightFeaturedText = props.isSpotlightListing
    ? 'Sponsored'
    : props.isFeaturedListing
    ? 'Featured'
    : ''

  const { shareHandler } = useShareProperty({ listing })

  const trackClickToPdp = useCallback(
    async function trackClickToPdp() {
      await yieldOrContinue('smooth')
      window.eventTracker?.track('click', {
        section: dataTagSection,
        item: 'property_title',
        geo_sort_property_match_type: props.locationRelativeToSearchedCity,
        listing_id: props.listing.id,
        listing_index: listingCardIndex,
        availability_status: listing.availabilityStatus,
      })
    },
    [
      dataTagSection,
      listingCardIndex,
      listing.availabilityStatus,
      props.listing.id,
      props.locationRelativeToSearchedCity,
    ]
  )

  function onMouseOver({ id, lat, lng }: ListingHandleMouseOverParams) {
    if (props.isActiveListing || props.isMobile || props.simplifiedCard) {
      return
    }

    // SRP -> Map indication
    if (props.isActiveListing) return
    if (lat && lng) {
      setActiveListing({
        selectionOrigin: SelectionOriginEnum.LIST,
        id,
        position: {
          lat,
          lng,
        },
      })
    }
  }

  const debouncedMouseOver = useDebouncedCallback(
    () =>
      onMouseOver({
        id: listing.id,
        lat: listing.location?.lat,
        lng: listing.location?.lng,
      }),
    150
  )

  const handleUpdateWins = useCallback(
    async function handleUpdateWins() {
      await yieldOrContinue('smooth')
      void updateWins({
        listingId: listing.id,
        photoId: listing.optimizedPhotos?.[0]?.id || '',
      })
    },
    [listing, updateWins]
  )

  const handleLinkClick = useCallback(
    async function handleLinkClick() {
      if (listing.urlPathname) {
        await yieldOrContinue('smooth')
        void handleUpdateWins()
      }
      await yieldOrContinue('smooth')
      void trackClickToPdp()
    },
    [listing.urlPathname, handleUpdateWins, trackClickToPdp]
  )

  const handleFirstInteraction = useCallback(
    async function handleFirstInteraction() {
      await yieldOrContinue('interactive')
      updatePlays({
        listingId: listing.id,
        photoId: listing.optimizedPhotos?.[0]?.id || '',
      })
    },
    [listing.id, listing.optimizedPhotos, updatePlays]
  )

  const srpInteractionHandler = useCallback(
    async function srpInteractionHandler() {
      if (!firstListingInteraction.current) {
        firstListingInteraction.current = true
        await yieldOrContinue('smooth')
        void submitUserActivitySrpInteraction()
      }
    },
    [submitUserActivitySrpInteraction]
  )

  const displayAddress = useMemo(() => {
    const { city, stateAbbr } = listing.location ?? {}

    if (
      city &&
      stateAbbr &&
      listing.zipCode &&
      listing.name &&
      listing.addressFull
        ?.toLowerCase()
        .startsWith(`${listing.name?.toLowerCase()},`)
    ) {
      return `${city}, ${stateAbbr} ${listing.zipCode}`
    }

    return listing.addressFull
  }, [listing])

  const displayedAmenities = useMemo(() => {
    return getAmenityText({
      // Only add on additional refinements for exact matches
      refinementSlugs: !props.isSoftMatchesResults
        ? props.currentRefinementSlugs ?? []
        : [],
      amenitiesHighlighted: listing.amenitiesHighlighted,
    })
  }, [
    listing.amenitiesHighlighted,
    props.currentRefinementSlugs,
    props.isSoftMatchesResults,
  ])

  const threeDTourElement = !simplifiedCard ? (
    <ThreeDTourButton
      listingId={listing.id}
      tourInfo={getHdTours(listing)}
      isListingCardRedesign
      className={styles.threeDTourButton}
      tourFromHomeModalRef={props.tourFromHomeModalRef}
    />
  ) : null

  const bedsOnlyText = props.withBedTextOnly
    ? getBedsText({
        bedRange: props.filterMatchResult?.beds,
        listingBedsText: listing.bedText,
      })
    : null

  return (
    <div
      className={clsx(styles.card, {
        [styles.activeFromMap]: props.isActiveFromMapPin,
        [styles.withUpdatedStyles]: withUpdatedStyles,
      })}
      data-tag_listing_id={props.listing.id}
      data-tag_listing_index={listingCardIndex}
      data-tag_section={dataTagSection}
      data-property-type={props.listing.propertyType}
      data-tplsource={props.listing.tplsource}
      onClick={srpInteractionHandler}
      onMouseOver={debouncedMouseOver}
      data-tid={
        Boolean(filterRelevance?.unmatched?.length)
          ? 'soft-match-listing-card'
          : undefined
      }
    >
      {hasTodaysTopPick && (
        <div className={styles.todaysTopPick}>
          <p>{todaysTopPickText}</p>
        </div>
      )}
      <div className={styles.carouselSection}>
        {(Boolean(spotlightFeaturedText) ||
          (locationMatchText?.length && !props.isMobile)) && (
          <div className={styles.textBackdrop} />
        )}

        {hasCategoryBadges && (
          <span
            className={clsx(styles.categoryBadgeText, {
              [styles.withUpdatedStyles]: withUpdatedStyles,
            })}
          >
            {listing.categoryBadges[0]}
          </span>
        )}

        {listing.hasHdTour && threeDTourElement}

        {Boolean(spotlightFeaturedText) && (
          <span
            className={clsx(
              styles.spotlightFeaturedText,
              withUpdatedStyles && styles.promotedWithUpdatedAgStyles
            )}
          >
            {spotlightFeaturedText}
          </span>
        )}
        {locationMatchText && (
          <div
            className={clsx(
              styles.locationBadge,
              withUpdatedStyles && styles.withUpdatedStyles
            )}
          >
            <LocationMatchBadge
              listing={listing}
              isMobile={props.isMobile}
              locationToDisplay={locationMatchText}
            />
          </div>
        )}
        <MemoizedListingCardScrollSnapCarousel
          lazyRenderSlides={props.lazyRenderSlides}
          listingId={listing.id}
          shouldUseFirstCarouselImageAsLCP={
            props.shouldUseFirstCarouselImageAsLCP
          }
          cityState={cityState}
          photos={simplifiedCard ? firstPhoto : listingPhotos}
          name={listing.name}
          urlPathname={listing.urlPathname}
          isMobile={props.isMobile}
          isUnpaid={listing.isUnpaid}
          onFirstInteraction={
            simplifiedCard ? undefined : handleFirstInteraction
          }
          onLinkClick={handleLinkClick}
          className={clsx(
            styles.carousel,
            withUpdatedStyles && simplifiedCard && styles.carouselSimplified,
            hasTodaysTopPick && styles.carouselTodaysTopPick
          )}
          dataTagSection={dataTagSection}
          disableSwipe={!isSwipeEnabledForMobile && props.isMobile}
        />
      </div>

      {showFilterRelevance ? (
        <ListingCardSoftFilters
          filterRelevance={filterRelevance}
          href={listing.urlPathname ?? ''}
          onClick={handleLinkClick}
          isMobile={props.isMobile}
          withUpdatedAgStyles={withUpdatedStyles}
          assumePerfectMatch={isPromotedListing}
        />
      ) : null}

      <div
        className={clsx(
          styles.basicDetails,
          showFilterRelevance && styles.withSoftFilters
        )}
      >
        <div className={styles.priceWrapper}>
          <span className={styles.price} data-tid="listing-price-text">
            {listingPriceText}
          </span>
          {listingPriceText !== CONTACT_FOR_PRICE && !simplifiedCard ? (
            <div className={styles.deals}>
              {dealsBadgeText ? (
                <Badge
                  data-tid="deals_badge"
                  text={dealsBadgeText}
                  className={styles.badge}
                />
              ) : (
                !listing.isUnpaid &&
                listing.hasPriceDrops && (
                  <Badge
                    data-tid="lower-price-badge"
                    text={
                      withUpdatedStyles ? 'New Low Price' : 'New Lower Price'
                    }
                    className={styles.badge}
                  />
                )
              )}
            </div>
          ) : null}
        </div>
        {!simplifiedCard && (
          <div className={styles.detailButtonsWrapper}>
            <ShareButton
              onClick={shareHandler}
              listingCardRedesignVersion={redesignVersion}
            />
            <FavoriteButton
              listingId={listing.id}
              listingCardRedesignVersion={redesignVersion}
            />
          </div>
        )}
      </div>

      <ListingCardDetailPageLink
        isMobile={props.isMobile}
        className={clsx(styles.linkText, styles.absoluteLink)}
        href={listing.urlPathname ?? ''}
        onClick={handleLinkClick}
        data-tid="pdp-link"
      >
        <>
          <Truncate className={styles.nameAddressSection}>
            <span className={styles.name}>{listing.name}</span>{' '}
            <span>{displayAddress}</span>
          </Truncate>

          {displayedAmenities ? (
            <Truncate className={styles.amenitiesList}>
              {displayedAmenities}
            </Truncate>
          ) : null}

          {props.withBedTextOnly ? (
            <span className={styles.bedsOnlyText}>{bedsOnlyText}</span>
          ) : null}
        </>
      </ListingCardDetailPageLink>

      {!simplifiedCard && (
        <BedCountData
          filterMatchResult={props.filterMatchResult}
          listing={props.listing}
          withUpdatedStyles={withUpdatedStyles}
        />
      )}

      {
        // For the instance of simplifiedCard used for the redesigned soft filters on SRP,
        // force the CTAs to display.
        // TODO: remove if soft_filters_redesign v3 fails
      }
      {!listing.isBasic && (!simplifiedCard || props.isSRPCard) && (
        <ListingCardResultsCTA
          ctaClass={styles.ctaButtons}
          listing={listing}
          currentRefinementSlugs={props.currentRefinementSlugs}
          isMobile={props.isMobile}
          locationRelativeToSearchedCity={props.locationRelativeToSearchedCity}
          onClick={handleUpdateWins}
        />
      )}
    </div>
  )
}

export function ListingCardRedesign(
  props: Omit<ListingCardProps, 'updatePlays'>
) {
  const { isSRPCard, listing, filterMatchResult, ...restProps } = props
  const updatePlays = useSetPlays(!!listing.optimizedPhotos?.length)

  const { options, taggingData, imageConversion } =
    useListingCardImpressionTracking({
      listing,
      isSRPCard,
      updatePlays,
      filterMatchResult,
      locationRelativeToSearchedCity: props.locationRelativeToSearchedCity,
      listingCardIndex: props.listingCardIndex,
    })

  return (
    <TrackImpression
      options={options}
      taggingData={taggingData}
      sectionName={imageConversion.eventCatergory}
    >
      <ListingCardRedesignBase
        {...restProps}
        isSRPCard={isSRPCard}
        listing={listing}
        updatePlays={updatePlays}
        filterMatchResult={filterMatchResult}
      />
    </TrackImpression>
  )
}
